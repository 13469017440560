import LoadingButton from './LoadingButton';
import PropTypes from 'prop-types'
import React from 'react'
import SuccessSign from './SuccessSign';
import axios from 'axios';
import vrGame from '../images/vrGame.jpg'
import molbitzImage from '../images/molbitzImage.jpg'
import bot from '../images/bot.jpg'

const INITIAL_STATE = {
  name: '',
  email: '',
  message: '',
  formState: 'notSubmitted'
}

class Main extends React.Component {

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  handleNameStateChange = (event) => {
    this.setState({ name: event.target.value });
  };

  handleEmailStateChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleMessageStateChange = (event) => {
    this.setState({ message: event.target.value });
  };

  onFormSubmit = async (event) => {
    event.preventDefault();
    await this.postMail();
  };

  postMail = async () => {
    const { name, email, message } = this.state;
    this.setState({ formState: 'loading' })
    try {
      await axios.post(
        process.env.NODE_ENV === 'production'
          ? 'https://contact.cratory.de/mail/me'
          : 'http://localhost:3333/mail/me',
        {
          name,
          email,
          message
        }
      );
      this.setState({ formState: 'success', name: '', email: '', message: '' });
    } catch (error) {
      this.setState({ formState: 'error' });
    }



  };

  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    );

    const { formState, name, email, message } = this.state;

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Twitter-Bot</h2>
          <span className="image main">
            <img src={bot} alt="" />
          </span>
          <p>
          I am deeply passionate about soccer, both as a player and an avid spectator of professional matches. 
          Immersing myself in the sport, I indulge in numerous football podcasts throughout the week. 
          One of my favorites is "Fritz & Stroh - Die Fussballshow," a Monday publication that delivers delightful coverage of German top-level football
          with a humorous twist.

          One segment of the show, aptly named "Zerfickungsalarm," 
          showcases games from around the globe that culminated in a goal difference of six or more. 
          Inspired by this concept, I developed a Twitter bot that automatically tweets whenever such a high-scoring game occurs. 
          If you're interested in following the bot, you can find it <a href="https://twitter.com/zerfickungsbot">here</a>. 
          The source code is available for inspection on <a href="https://gitlab.com/13_mark/zerfickungsbot">GitLab</a>.
          </p>
          <p>
            <b>
              <u>Technical details:</u>
              <br />
            </b>{' '}
            This project is built on plain Node.js, with TypeScript being the only standout addition (though it's becoming quite commonplace these days). 
            It involves an API call (<a href="https://rapidapi.com/api-sports/api/api-football">link</a>), 
            flag emoji lookups (<a href="https://www.npmjs.com/package/emoji-flags">npm-package</a>) and utilizes a PostgreSQL database for minimal data persistence.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Freiheit-DIY</h2>
          <span className="image main">
            <img src={vrGame} alt="" />
          </span>
          <p>
            In the spring of 2022, I had the privilege of participating in the <a href="https://codingdavinci.de/de/events/ost3-2022">Coding-da-Vinci Ost</a> hackathon, a cultural event where various institutions,
            including museums and research projects, generously provided public data via diverse APIs. 
            This wealth of data served as the foundation for participants to craft innovative ways of engaging with it.
            Teaming up with two fellow enthusiasts, we delved into a dataset graciously provided by the Zuse Computer Museum Hoyerswerda. 
            This dataset showcased over a hundred electric components originating from the former German Democratic Republic (DDR). 
            These components, ingeniously utilized by individuals during their leisure time, held historical significance, 
            such as the creation of a "UHF-Konverter" device, enabling DDR citizens to clandestinely access television signals from the 
            Federal Republic of Germany (BRD), a prohibited act at the time.

            Driven by a desire to immerse others in this historical narrative, we embarked on developing a virtual reality game. 
            Through our creation, users could step into the past and experience the process of constructing such a device, 
            all while gaining insights into the context of that era. 
            Our efforts garnered positive acclaim when we showcased the project at a subsequent hackathon, earning us a coveted scholarship from 
            the <a href="https://codingdavinci.de/de/news/neue-stipendienprojekte-nehmen-die-deutsche-geschichte-den-fokus">Coding-da-Vinci Foundation</a>, an opportunity we embraced with gratitude.

            By December 2022, we had brought our vision to fruition, and our VR game found its place within the exhibition program at the 
            Zuse Computer Museum in Hoyerswerda. 
            Our project even attracted attention from local newspapers, with coverage available in the <a href="https://www.saechsische.de/hoyerswerda/lokales/junge-menschen-bringen-analoge-objekte-in-die-digitale-welt-5766447-plus.html">Sächsische Zeitung</a> and <a href="https://hoyte24.de/nachrichten/ddr-technik-basteleien-nacherlebbar-gemacht">Hoyte24</a>.
          </p>
          <p>
            <b>
              <u>Technical details:</u>
              <br />
            </b>
            The VR game was crafted using the Unity engine, marking a departure from my usual web-based projects and offering a fresh learning curve. 
            This endeavor provided me with invaluable opportunities to refine my C# skills and delve into new realms, 
            including designing 3D models with Blender, video and audio editing, and mastering various game development techniques. 
            For those interested, the source code is available on <a href="https://github.com/ForzaMark/freiheit-diy">Github</a>.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Molbitzer-Turniere</h2>
          <span className="image main">
            <img src={molbitzImage} alt="" />
          </span>
          <p>
            Introducing molbitzer-turniere.de, a website dedicated to showcasing the annual EA Sports FIFA Tournament and 
            Darts Tournament held in the charming East Thuringian village of Molbitz. 
            These events draw in a vibrant crowd of 50 to 100 participants each year, bringing together gaming and sporting enthusiasts for thrilling competitions.

            Designed with simplicity in mind, the website provides a straightforward presentation of the tournaments, 
            complete with results and overall statistics. Whether you're a seasoned competitor or a curious observer, <a href="https://www.molbitzer-turniere.de">molbitzer-turniere.de</a>
            {' '} offers a glimpse into the excitement of these community gatherings.
          </p>
          <p>
            <b>
              <u>Technical details:</u>
            </b>
            <br />

            Behind the scenes, this project serves as a development playground, allowing for experimentation with new techniques and a focus on optimizing loading performance and SEO.
            Leveraging the power of Nrwl Nx Tools, a set of development tools tailored for managing and scaling JavaScript Monorepos, 
            the project is structured for efficiency and scalability.

            For the website's styling, Tailwind CSS Framework was employed, adopting a mobile-first approach to ensure seamless accessibility across devices. 
            For those interested in exploring the inner workings, the source code is available for inspection <a href="https://gitlab.com/13_mark/molbitz-tournaments">here</a>.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <p>
          I'm Mark, a 24-year-old software engineer from Leipzig, Germany. 
          In 2021, I graduated with a degree in Computer Science from the "Berlin School of Economics and Law." 
          Following my graduation, I gained valuable experience as a Web Developer at <a href="https://t2informatik.de/">t2informatik</a>, 
          where I worked for two years.

          Currently, I am studying Data Science for my Master's degree at the University of Leipzig. 

          I'm honored to be a fellow of the "Studienstiftung des deutschen Volkes" scholarship program, 
          which has provided invaluable support throughout my academic journey. 
          Additionally, I am an alum of the "Coding da Vinci" scholarship program.

        </p>
        <p>
          If you'd like to connect with me, please feel free to reach out via this <a href='https://share-eu1.hsforms.com/1dGqapxWyQEWtMeesMWAAdwfxpp9'>link</a>.
           
           
            
            <hr style={{
              marginTop: 30,
              marginBottom: 30
            }} />
            <u>Other projects:</u>
            <ul>
              <li>Personal Blog/Wiki (<a href="https://blog.cratory.de">blog.cratory.de</a>)</li>
              <li>Typewriter WebComponent (<a href="https://github.com/ForzaMark/type-writer-web-component">Github-Repo</a>)</li>
              <li>Mobirise-Angular (<a href="https://github.com/ForzaMark/mobirise-angular">Github-Repo</a>)</li>
              <li>Twitter-Bot for <a href='https://mobile.twitter.com/fums_dieshow'>"FUMS — DIE SHOW"</a> (<a href="https://mobile.twitter.com/zerfickungsbot">Bot</a>, <a href="https://gitlab.com/13_mark/zerfickungsbot">Gitlab</a>)</li>
              <li>Soccer-Prediction (<a href="https://gitlab.com/13_mark/spi-bet-prediction">Gitlab</a>)
              </li>
            </ul>
          </p>
          
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/the13_mark"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://gitlab.com/13_mark" className="icon fa-gitlab">
                <span className="label">Gitlab</span>
              </a>
            </li>
            <li>
              <a href="https://github.com/ForzaMark" className="icon fa-github">
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          <p>
            Icon-Credits to &nbsp;
            <a href="https://www.freepik.com" title="Freepik">
              Freepik
            </a>
            &nbsp; and Image-Credits to{' '}
            <a href="https://unsplash.com/@lucabravo">Luca Bravo</a>,{' '}
            <a href="https://unsplash.com/@nampoh">Maxim Hopman</a>,{' '}
            <a href="https://unsplash.com/photos/HkN64BISuQA">Ariel</a>{' '} and {' '}
            <a href="https://unsplash.com/photos/IEwZRIVwoCs">Isaiah Rustad,</a>{' '}
            <a href="https://unsplash.com/de/@phillipglickman?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Phillip Glickman</a> 
  
          </p>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
